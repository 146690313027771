/*----- Progress ------*/

.progress {
	display: -ms-flexbox;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: .703125rem;
	background-color: rgba(241, 238, 247, 0.8);
	border-radius: 2px;
	-webkit-box-shadow: 0 10px 20px 0 rgba(105, 109, 131, 0.3);
	box-shadow: 0 10px 20px 0 rgba(105, 109, 131, 0.1);
	position: relative;
}

.progress-bar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: $white;
	text-align: center;
	white-space: nowrap;
	transition: width .6s ease;
}

.current-progressbar {
	margin-top: 14.5px;
}

.progress-content {
	margin-bottom: 16.5px;
	&:last-child {
		margin-bottom: 0;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.progress-bar {
		transition: none;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}

.progress-xs {
	height: .25rem;
	.progress-bar {
		height: .25rem;
	}
}

.progress-sm {
	height: .5rem;
	.progress-bar {
		height: .5rem;
	}
}

.progress-lg {
	height: 1.25rem;
	.progress-bar {
		height: 1.25rem;
	}
}

.progress-bar-indeterminate {
	&:after {
		content: '';
		position: absolute;
		background-color: inherit;
		left: 0;
		will-change: left, right;
		top: 0;
		bottom: 0;
	}
	&:before {
		content: '';
		position: absolute;
		background-color: inherit;
		left: 0;
		will-change: left, right;
		top: 0;
		bottom: 0;
		-webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
		animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
	}
	&:after {
		-webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
		animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
		-webkit-animation-delay: 1.15s;
		animation-delay: 1.15s;
	}
}

@-webkit-keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}