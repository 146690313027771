/*------ Heights -------*/

.h100 {
	height: 100px !important;
}

.h73 {
	height: 73px !important;
}

.h-125 {
	height: 125px !important;
}

.h-150 {
	height: 150px !important;
}

.h-196 {
	height: 196px !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.h-0 {
	height: 0 !important;
}

.h-1 {
	height: 0.25rem !important;
}

.h-2 {
	height: 0.5rem !important;
}

.h-3 {
	height: 0.75rem !important;
}

.h-4 {
	height: 1rem !important;
}

.h-5 {
	height: 1.5rem !important;
}

.h-6 {
	height: 2rem !important;
}

.h-7 {
	height: 3rem !important;
}

.h-8 {
	height: 4rem !important;
}

.h-9 {
	height: 6rem !important;
}

.h-300 {
	height: 300px !important;
}

.h-400 {
	height: 400px !important;
}

.h-220 {
	height: 220px !important;
}

.h-280 {
	height: 280px !important;
}

.mh-100 {
	max-height: 100% !important;
}

.minh-210 {
	min-height: 210px !important;
}
