.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.875rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 45px !important;
	}
}

.select2-sm {
	.select2-container .select2-selection--single {
		height: 1.775rem !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 27px !important;
		}
	}
}

.select2-container--default {
	.select2-selection--single {
		background-color: $white;
		border: 1px solid $border !important;
		border-radius: 4px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: #3d4e67;
		background-color: $white;
		border-color: #4c8aec;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $white;
		border: 1px solid $border !important;
		border-radius: 4px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid $border !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $background !important;
		border: 1px solid $background !important;
	}
	.select2-selection--single .select2-selection__rendered {
		color: #444;
		line-height: 39px !important;
		color: #364154 !important;
	}
}

@media (max-width: 1024px) {
	.select2-container {
		width: 100% !important;
	}
}

.select2-container .select2-selection--single {
	height: 2.375rem;
	height:100% !important;
}
 

.select-languages,
.select-currency {
	.select2-container .select2-selection--single {
		height: 1.4rem !important;
		color: #a7b4c9 !important;
		border-radius: 2px;
		background: 0 0 !important;
		border: 0 !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20.5px !important;
		}
		.select2-selection__arrow {
			top: -2px !important;
		}
	}
	.select2-container {
		width: 138px !important;
	}
	.select2-results {
		color: #a7b4c9 !important;
	}
}

.select-country {
	.select2-container {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-left: 3px;
			}
			height: 1.4rem !important;
			color: #a7b4c9 !important;
			border-radius: 2px;
			background: 0 0 !important;
			border: 0 !important;
		}
		width: 150px !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 21px !important;
			color: #504e70 !important;
		}
		.select2-selection__arrow {
			top: -3px !important;
		}
	}
	.select2-results {
		color: #a7b4c9 !important;
	}
}

.select2-results__option {
	font-size: 13px;
}

.my-select .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100% !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
        text-align:left;
}
@media (max-width:991.98px) {
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		right: 10px !important;
	}
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 4px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $color !important;
    margin-right: 4px !important;
}