/*------ Headers ------*/

.header {
	// padding-top: 0.75rem;
	// padding-bottom: 0.75rem;
	width: 100%;
	z-index: 100;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media print {
	.header {
		display: none;
	}
}

.header {
	.dropdown-menu {
		margin-top: 46px;
		top: 10px !important;
	}
	.mega-menu {
		width: 350px;
	}
}

.nav-link.icon {
	margin: 5px;
	border-radius: 50%;
	padding: 11px;
	width: 2.5rem;
	text-align: center;
	height: 2.5rem;
	font-size: 15px;
	position: relative;
	&.full-screen-link {
		padding-bottom: 12px;
	}
	i {
		color: #fcfdff;
	}
}

.header-brand {
	margin: 0 auto;
	text-align: center;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: 0.3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: 0.3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	margin-top: -1.5px;
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 100%;
	line-height: 2rem;
	vertical-align: bottom;
	width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
	&.has-new {
		position: relative;
		&:before {
			content: "";
			width: 6px;
			height: 6px;
			background: $danger;
			position: absolute;
			top: 4px;
			right: 4px;
			border-radius: 50%;
		}
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: $white;
	&:hover {
		color: $white;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 3px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -0.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.header {
	.nav-item .badge,
	.nav-link .badge {
		font-size: 10px;
		position: absolute;
		top: 0;
		right: 4px;
		padding: 8px;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10px;
		height: 10px;
	}
}

#headerMenuCollapse {
	.nav-item .badge,
	.nav-link .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

.header-main .social-icons {
	float: right;
	li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #212529;
		}
	}
}

.header-search {
	padding: 1.5rem 1.5rem;
	background: $white;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li {
				background: $background;
				width: 40px;
				height: 40px;
				border-radius: 50px;
				text-align: center;
				line-height: 2.5;
				margin-right: 5px;
			}
			.header-icons-link1,
			.header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -9px;
			right: -15px;
			text-align: center;
			font-size: 10px;
		}
		.header-icons-link.icons li {
			background: $white;
		}
	}
}

.top-bar {
	border-bottom: 1px solid #e8ebf3;
	background: $white;
}

.top-bar-left ul {
	margin-bottom: 0;
}

.top-bar {
	.top-bar-right {
		float: right;
		display: flex;
	}
	.top-bar-left {
		display: flex;
		.socials li {
			display: inline-block;
			float: left;
			font-size: 15px;
			margin: 9px 15px;
		}
		.contact li {
			margin: 10px 5px;
			display: inline-block;
			color: $color;
		}
	}
	.select-country .select2-container.select2-container--default.select2-container--below.select2-container--open {
		width: 150px !important;
	}
}

.header-search {
	.header-inputs .input-group-append.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #a7b4c9;
		}
		a {
			color: #a7b4c9;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 3px;
		}
	}
}

.header-main {
	.top-bar .contact {
		margin: 9px 0;
		padding-left: 15px;
		li {
			margin: 0;
		}
	}
	.top-bar-right .custom {
		display: flex;
		margin: 10px 0;
		li {
			margin-right: 15px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.header-main {
	.ace-responsive-menu li a {
		color: $color;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header-menu1 {
	float: none !important;
}

@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}
}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
	.horizontal-main,
	.horizontalMenu > .horizontalMenu-list {
		background: 0 0;
	}
}

.header-style .horizontalMenu {
	float: inherit !important;
}

.top-bar {
	.select2-dropdown.select2-dropdown--below {
		width: 180px !important;
	}
	.select-country .select2-container {
		width: 150px !important;
	}
}

.header-text1 .text-property {
	position: relative;
	top: -15px;
}
@media (max-width: 991.98px) {
	.responsive-logo .header-brand {
		.header-brand-img.mobile-logo,
		.header-brand-img.desktop-dark {
			display: none;
		}
		.header-brand-img.desktop-logo {
			display: block;
		}
	}
	.header-brand-img {
		width: auto !important;
	}
}
@media (min-width: 992px) {
	.navbar-collapse {
		display: block !important;
	}
}
@media (max-width: 991.98px) {
	.country-selector .leading-none {
		justify-content: center;
	}
	.navbar-collapse .dropdown .dropdown-menu {
		border-radius: 0px 0px 5px 5px;
	}
	// .country-selector .nav-link {
	// 	padding: 0.2rem;
	// }
	.header-right .responsive-navbar .dropdown {
		position: initial;
	}
	.responsive-navbar .collapse.show .dropdown-menu.show {
		left: 5% !important;
		right: 5% !important;
	}
	.responsive-navbar .collapse.show .dropdown-menu.show {
		display: block !important;
	}
	.header .dropdown-menu {
		min-width: 17rem;
	}
	.dropdown-menu:before,
	.dropdown-menu:after {
		display: none;
	}
	.nav-link.navsearch-icon {
		margin: 5px;
		border-radius: 50%;
		padding: 10px;
		width: 2.5rem;
		text-align: center;
		height: 2.5rem;
		font-size: 15px;
		position: relative;
	}
	.header {
		padding-top: 0.95rem;
		padding-bottom: 0.95rem;
	}
	.sidebar-mini .responsive-navbar .navbar-collapse,
	.responsive-navbar .navbar-collapse {
		margin-top: 69px !important;
	}
	.navbar-toggler-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #5f7491;
	}
	.navbar-toggler {
		padding: 6px 14px;
		font-size: 1.4rem;
		line-height: 1;
		background-color: transparent;
		border: 1px solid transparent;
		border-radius: 3px;
		position: relative;
	}
	.navresponsive-toggler span {
		margin-top: 8px;
		text-align: center;
		position: relative;
		color: #070510;
	}
	.header-brand-img {
		margin: 5px auto;
	}
	.header-brand {
		display: block !important;
		left: 0;
		position: absolute;
		right: 0;
		width: inherit;
	}
	.responsive-navbar .navbar-collapse {
		padding: 3px;
		position: fixed;
		width: 100%;
		background: #fff;
		margin-top: 41px;
		z-index: 999;
		box-shadow: 0 12px 11px -3px rgba(104, 113, 123, 10%);
		left: 0;
		right: 0;
		top: 0;
	}
	.leading-none.user-img .avatar.avatar-md {
		margin: 0.6rem 5px 5px 5px;
	}
}
@media (max-width: 575.98px) {
	.country-selector .leading-none p {
		display: none;
	}
	.country-selector a {
		margin: 5px;
		border-radius: 50%;
		padding: 11px;
		width: 2.5rem;
		text-align: center;
		height: 2.5rem;
		font-size: 15px;
	}
}
@media (max-width: 420px) {
	.nav-link.navsearch-icon,
	.country-selector a,
	.nav-link.icon {
		margin: 5px 0px;
	}
	.leading-none.user-img .avatar.avatar-md {
		margin: 0.5rem;
	}
}
.layout-setting .dark-layout {
	display: block;
}
.layout-setting .light-layout {
	display: none;
}
@media (max-width:575.98px) {
	.nav-link.icon.fullscreen {
		display: none;
	}
}
.header-msg {
	white-space: normal;
	width: 250px;
}
.header-notify {
	white-space: normal;
	width: 180px;
}
.header-page .header-brand {
	margin: 0 !important;
		.header-brand-img {
			height: auto;
		}
}
.app-header1 {
	.dropdown-icon {
		color: $primary;
	}
}