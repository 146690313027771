/*---- Panel ----*/

.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: $border;
}

.panel-default>.panel-heading {
	padding: 0;
	border-radius: 3px;
	background-color: $background;
	border-color: $border;
}

.panel-title {
	font-size: 14px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
		border: 1px solid $border;
	}
}

.more-less {
	float: right;
	color: #212121;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid $border;
}

a {
	&:active,
	&:focus,
	&:hover {
		outline: 0;
		text-decoration: none;
	}
}

.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: 0 0;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}

.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}

.panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
}

.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}

.panel-heading1 {
	background-color: $white;
	border-radius: 0;
	border: none;
	color: $color;
	padding: 0;
}

.panel-group1 .panel-body {
	border: 1px solid $border;
}

.panel-title1 a {
	display: block;
	padding: 15px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
	border: 1px solid $border;
	&.collapsed {
		color: $color;
	}
}

.panel-body1 {
	background: $white;
	padding: 10px;
}

.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius .3s linear .2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}

.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}

.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: feather !important;
			right: 10px;
			top: 12px;
			font-size: 20px;
			transition: all .5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: feather !important;
			right: 10px;
			top: 12px;
			font-size: 20px;
			transition: all .5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all .5s;
		transform: scale(0);
	}
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
	margin-bottom: 0;
}

#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all .5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all .5s;
	}
}

#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all .5s;
	}
	&.active a:before {
		transform: rotate(0);
		transition: all .5s;
	}
}


/*------ Expanels ------*/

.expanel-default {
	border: #ddd !important;
}

.expanel-secondary {
	border: $secondary !important;
}

.expanel-success {
	border: $success !important;
}

.expanel-danger {
	border: $danger !important;
}

.expanel-secondary>.expanel-heading {
	color: $white !important;
	background-color: $secondary !important;
	border-color: $secondary !important;
}

.expanel-success>.expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
}

.expanel-danger>.expanel-heading {
	color: $white !important;
	background-color: $danger !important;
	border-color: $danger !important;
}

.expanel-warning>.expanel-heading {
	color: $white !important;
	background-color: #ecd938 !important;
	border-color: #ecd938 !important;
}

.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}

.expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid $border !important;
	border-radius: 4px !important;
	// box-shadow: 0 1px 1px $black-05 !important;
}

.expanel-default>.expanel-heading {
	background-color: $background !important;
	border-color: 1px solid $border !important;
}

.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.expanel-footer {
	padding: 10px 15px !important;
	background-color: $background !important;
	border-top: 1px solid $border !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.expanel-body {
	padding: 15px !important;
}
@media (max-width:767px) {
	.panel-title1 a {
		white-space: break-spaces;
	}
}

.panel-title > a[aria-expanded="false"] {
	border-radius: 3px; 
}
  
.panel-title > a[aria-expanded="true"] {
	border-radius: 3px 3px 0 0; 
}
  
#accordion1 .panel-collapse.collapse.show .panel-body {
	border-top: 0;
	border-radius: 0 0 3px 3px; 
}

.panel-title1 > a[aria-expanded="false"] {
	border-radius: 3px; 
}
  
.panel-title1 > a[aria-expanded="true"] {
	border-radius: 3px 3px 0 0; 
}

#accordion2 .panel-collapse.collapse.show .panel-body {
	border-top: 0;
	border-radius: 0 0 3px 3px; 
}